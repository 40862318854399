import React, {Component} from 'react';
import {FilePicker} from 'react-file-picker'
import {Alert, AlertTitle, Button, Snackbar} from "@mui/material";

class Gallery extends Component {
    state = {
        images: [],
        openAlert: false,
    };

    componentDidMount() {
        this.updateImages();
        setInterval(this.updateImages, 1000)
    }

    updateImages = async () => {
        const url = 'https://share.base-epic.ru/list';
        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({login: this.props.login}),
            });

            const data = await response.json();

            if (data.status === 'ok') {
                if (data.images && data.images !== this.state.images) {
                    this.setState({images: data.images});
                }
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    handleFileUpload = (file) => {
        // Assuming you have an API endpoint for uploading images
        const uploading_url = 'https://share.base-epic.ru/upload';
        const adding_url = 'https://share.base-epic.ru/add';
        const formData = new FormData();
        formData.append('file', file);

        try {
            fetch(uploading_url, {
                method: 'POST',
                mode: 'cors',
                body: formData,
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.status === 'ok') {
                        fetch(adding_url, {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                'Content-Type': 'application/json;charset=utf-8'
                            },
                            body: JSON.stringify({
                                login: this.props.login,
                                path: data.path,
                            }),
                        }).then((response) => response.json())
                            .then((data) => {
                                if (data.status === 'ok') {
                                    this.setState({
                                        openAlert: true,
                                        alertMessage: 'Успешно',
                                        alertSeverity: 'success',
                                    });
                                } else {
                                    this.setState({
                                        openAlert: true,
                                        alertMessage: 'Ошибка: ' + data.message,
                                        alertSeverity: 'error',
                                    });
                                }
                            })
                            .catch((error) => {
                                this.setState({
                                    openAlert: true,
                                    alertMessage: 'Ошибка: ' + error,
                                    alertSeverity: 'error',
                                });
                            });
                    }
                })
                .catch((error) => {
                    this.setState({
                        openAlert: true,
                        alertMessage: 'Ошибка: ' + error,
                        alertSeverity: 'error',
                    });
                });
        } catch (error) {
            this.setState({
                openAlert: true,
                alertMessage: 'Ошибка: ' + error,
                alertSeverity: 'error',
            });
        }

    };

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({openAlert: false});
    };

    handleImageClick = (image) => {

        const full_url = window.location.protocol+"//"+window.location.host+"/"+image;
        navigator.clipboard.writeText(full_url);
        this.setState({
            openAlert: true,
            alertMessage: 'URL изображения скопирован в буфер обмена: ' + full_url,
            alertSeverity: 'success',
        });
    };

    render() {
        return (
            <div>
                <FilePicker
                    extensions={['jpg', 'jpeg', 'png']}
                    dims={{minWidth: 100, maxWidth: 4000, minHeight: 100, maxHeight: 4000}}
                    onChange={FileObject => (this.handleFileUpload(FileObject))}
                    onError={errMsg => (
                        this.setState({
                            openAlert: true,
                            alertMessage: 'Ошибка: ' + errMsg,
                            alertSeverity: 'error',
                        }))}>
                    <Button className="image-picker-button" variant="contained">
                        Загрузить изображение
                    </Button>
                </FilePicker>
                <div className="image-holder">
                    {this.state.images.map((image, index) => (
                        <img className="gallery-img" src={image}
                             alt={`Image ${index + 1}`}
                             onClick={() => this.handleImageClick(image)}/>
                    ))}
                </div>
                <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={this.handleCloseAlert}>
                    <Alert onClose={this.handleCloseAlert} severity={this.state.alertSeverity} sx={{width: '100%'}}>
                        <AlertTitle>{this.state.alertSeverity === 'success' ? 'ОК' : 'Ошибка'}</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </div>
        )
            ;
    }
}

export default Gallery;
