import React, {Component} from 'react';
import Auth from "./Auth";
import Gallery from "./Gallery";
import './App.css';
import Userbar from "./Userbar";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status: 0,
            login: ""
        };
    }

    componentDidMount() {
        this.checkAuthStatus();
    }

    checkAuthStatus = async () => {
        try {
            const response = await fetch("https://share.base-epic.ru/auth/login-with-token", {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({token: localStorage.getItem('token') || ''}),
            });

            const data = await response.json();

            if (data.status === 'ok') {
                this.setState({
                    status: 2,
                    login: data.login,
                });
            } else {
                this.setState({
                    status: 1,
                });
            }
        } catch (error) {
            this.setState({
                status: 1
            });
        }
    }

    render() {
        return (
            <div>
                {this.state.status === 1 &&
                    <Auth/>
                }
                {this.state.status === 2 &&
                    <>
                        <Userbar
                            login={this.state.login}/>
                        <Gallery
                            login={this.state.login}/>
                    </>
                }
            </div>
        );
    }
}

export default App;
