import {Component} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

class Userbar extends Component {

    clearToken = () => {
        localStorage.removeItem('token');
        window.location.reload();
    }
    render() {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <AppBar sx={{ borderRadius: 5 }} position="static">
                    <Toolbar>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            {this.props.login}
                        </Typography>
                        <Button variant="contained" color="success" onClick={this.clearToken}>Выход</Button>
                    </Toolbar>
                </AppBar>
                <br/>
            </Box>
        );
    }
}

export default Userbar;
