import React, { Component } from 'react';
import { Alert, AlertTitle, Box, Button, Snackbar, TextField, Typography } from '@mui/material';

class Auth extends Component {
    constructor(props) {
        super(props);
        this.state = {
            login: '',
            password: '',
            openAlert: false,
            alertMessage: '',
            alertSeverity: 'success', // 'success', 'error'
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleAuth = async (register) => {
        const { login, password } = this.state;

        let url = 'https://share.base-epic.ru/auth/login';
        if (register) {
            url = 'https://share.base-epic.ru/auth/register';
        }

        try {
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                body: JSON.stringify({ login, password }),
            });

            const data = await response.json();

            if (data.status === 'ok') {
                localStorage.setItem('token', data.token);
                this.setState({
                    openAlert: true,
                    alertMessage: 'Успешно',
                    alertSeverity: 'success',
                });
                window.location.reload();
            } else {
                this.setState({
                    openAlert: true,
                    alertMessage: 'Ошибка: '+data.message,
                    alertSeverity: 'error',
                });
            }
        } catch (error) {
            this.setState({
                openAlert: true,
                alertMessage: 'Ошибка: ' + error.message,
                alertSeverity: 'error',
            });
        }
    };

    handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openAlert: false });
    };

    render() {
        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                minHeight="100vh"
            >
                <Typography variant="h4" gutterBottom>
                    Заходи, не стесняйся
                </Typography>
                <Box>
                    <Box mb={2}>
                        <TextField
                            label="Логин"
                            name="login"
                            value={this.state.login}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </Box>
                    <Box mb={2}>
                        <TextField
                            label="Пароль"
                            type="password"
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            fullWidth
                        />
                    </Box>
                    <Box display="flex" justifyContent="space-between" width="100%">
                        <Button variant="contained" color="success"  onClick={()=>{this.handleAuth(true)}}>
                            Регистрация
                        </Button>
                        <Button variant="contained" color="primary" onClick={()=>{this.handleAuth(false)}}>
                            Вход
                        </Button>
                    </Box>
                </Box>
                <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={this.handleCloseAlert}>
                    <Alert onClose={this.handleCloseAlert} severity={this.state.alertSeverity} sx={{ width: '100%' }}>
                        <AlertTitle>{this.state.alertSeverity === 'success' ? 'Success' : 'Error'}</AlertTitle>
                        {this.state.alertMessage}
                    </Alert>
                </Snackbar>
            </Box>
        );
    }
}

export default Auth;
